import React, { PropsWithChildren } from "react";
import { ParametersProvider } from "common/hooks/Parameters/useParameters";
import { LanguageProvider } from "common/hooks/Parameters/useLanguage";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "common/hooks/auth/useAuth";
import { createCustomTheme } from "@neurosolutionsgroup/themes";
import {
  AnalyticService,
  AnalyticsProvider,
  AnalyticsServices,
} from "@neurosolutionsgroup/analytics";
import environmentVariable from "common/Tools/Env";
import { Tools } from "@neurosolutionsgroup/tools";
import { ErrorsProvider } from "common/hooks/errors/ErrorContext";
import { NavigationProvider } from "common/hooks/navigation/NavigationContext";
import { AppInitializationProvider } from "common/hooks/AppInitializationContext";
import {
  ProjectType,
  RemoteConfigProvider,
} from "@neurosolutionsgroup/remote-config";
import { getApp } from "firebase/app";
import { getMixpanelService } from "@neurosolutionsgroup/mixpanel-analytics";
import { getCustomerIOService } from "@neurosolutionsgroup/cio-analytics";
import { getFirebaseAnalyticsService } from "@neurosolutionsgroup/firebase-analytics";

// MUI module declarations.
declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    contained: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }

  interface ButtonVariants {
    link: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    link?: React.CSSProperties;
  }

  interface TypographyVariants {
    label: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

const AppProviders: React.FC<PropsWithChildren> = (props) => {
  const theme = createCustomTheme();

  const app = getApp();

  const services: AnalyticService[] = [];

  if (process.env.NODE_ENV === "development") {
    services.push(AnalyticsServices.ConsoleService);
  } else {
    services.push(
      getMixpanelService(
        Tools.Environment.getRequiredEnvironmentVariable(
          environmentVariable.MixpanelToken
        )
      )
    );

    services.push(
      getCustomerIOService(
        Tools.Environment.getRequiredEnvironmentVariable(
          environmentVariable.FunctionsURL
        ) + "/analytics"
      )
    );

    services.push(getFirebaseAnalyticsService(app, "webviews"));
  }

  return (
    <ThemeProvider theme={theme}>
      <AppInitializationProvider>
        <RemoteConfigProvider
          isDevEnv={Tools.Environment.isDevBuild()}
          product={"Kairos"}
          project={ProjectType.WEBVIEWS}
          app={app}
        >
          <AnalyticsProvider services={services}>
            <LanguageProvider>
              <ParametersProvider>
                <NavigationProvider>
                  <AuthProvider>
                    <ErrorsProvider>
                      <>{props.children}</>
                    </ErrorsProvider>
                  </AuthProvider>
                </NavigationProvider>
              </ParametersProvider>
            </LanguageProvider>
          </AnalyticsProvider>
        </RemoteConfigProvider>
      </AppInitializationProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
